import React, { useEffect, useState } from 'react'
import { Race } from '../../models/race'
import { Button, Grid, Tooltip } from '@material-ui/core'
import { createRaceState } from '../../services/create_race_state'
import getRace from '../../services/get_race'
import {
    GenericRealTimeTracker,
    GenericRealTimeTrackerRaceState,
    GenericRealTimeTrackerRaceStateGroup,
    GenericRealTimeTrackerRecall,
} from '../../services/generic_real_time_tracker'
import { useDispatch, useSelector } from 'react-redux'
import { RootStateType } from '../../store'
import { RaceState } from '../../models/race_state'
import { ButtonWithConfirmation } from './RaceCard'
import { getErrorData } from '../../services/get_error'
import { HarnessLandmarkDisplay } from '../generic_historical_tracker/HarnessLandmarkDisplay'

export const RaceButtons = ({
    race,
    tracker,
    videoSource,
    updateUpcomingRaces,
}: {
    race: Race
    tracker: GenericRealTimeTracker
    videoSource: string
    updateUpcomingRaces: () => void
}) => {
    const buttonColumns = [
        ...new Set(
            tracker.GenericRealTimeTrackerRaceStateGroups.filter((g) => g.ButtonColumn > 0).map((g) => g.ButtonColumn)
        ),
    ]
    return (
        <>
            <Grid container direction={'row'} spacing={1} justifyContent={'center'} alignItems={'center'}>
                {buttonColumns.map((bc) => {
                    return (
                        <Grid item style={{ order: bc }} key={bc}>
                            <Grid
                                container
                                direction={'column'}
                                spacing={1}
                                justifyContent={'center'}
                                alignItems={'center'}
                            >
                                {tracker.GenericRealTimeTrackerRaceStateGroups.filter(
                                    (rsg) =>
                                        (rsg.RaceType === null || rsg.RaceType === race.raceType) &&
                                        (rsg.SubRaceType === null || rsg.SubRaceType === race.raceSubType) &&
                                        (rsg.StartType === null || rsg.StartType === race.startType) &&
                                        rsg.ButtonColumn === bc
                                )
                                    .sort((a, b) => (a.ButtonColumnOrder ?? 0) - (b.ButtonColumnOrder ?? 0))
                                    .map((rsg) => (
                                        <React.Fragment key={rsg.Name}>
                                            <Grid item>
                                                {!rsg.IsOptionsGroup && (
                                                    <SequentialRaceStateButtonGroup
                                                        ButtonGroup={rsg}
                                                        Race={race}
                                                        VideoSource={videoSource}
                                                        UpdateUpcomingRaces={updateUpcomingRaces}
                                                        PracticeMode={tracker.PracticeMode}
                                                        Tracker={tracker}
                                                    />
                                                )}
                                                {rsg.IsOptionsGroup && (
                                                    <OptionsRaceStateButtonGroup
                                                        ButtonGroup={rsg}
                                                        Race={race}
                                                        VideoSource={videoSource}
                                                        UpdateUpcomingRaces={updateUpcomingRaces}
                                                        PracticeMode={tracker.PracticeMode}
                                                        Tracker={tracker}
                                                    />
                                                )}
                                            </Grid>
                                        </React.Fragment>
                                    ))}
                            </Grid>
                        </Grid>
                    )
                })}
            </Grid>
            {race.raceType === 'HARNESS' && (
                <HarnessLandmarkDisplay trackCode={race.trackCode} runners={race.runners} />
            )}
            {race.raceType === 'TROT' && race.startType === 'AUTOMOBILE' && (
                <HarnessLandmarkDisplay trackCode={race.trackCode} distance={race.distance} />
            )}
        </>
    )
}

interface RaceStateButtonGroupProps {
    ButtonGroup: GenericRealTimeTrackerRaceStateGroup
    Race: Race
    VideoSource: string
    UpdateUpcomingRaces: () => void
    PracticeMode: boolean
    Tracker: GenericRealTimeTracker
}

const SequentialRaceStateButtonGroup = ({
    ButtonGroup,
    Race,
    VideoSource,
    UpdateUpcomingRaces,
    PracticeMode,
    Tracker,
}: RaceStateButtonGroupProps) => {
    const [order, setOrder] = useState(1)
    const latency = useSelector((state: RootStateType) => state?.latencyStore?.latencyInSeconds ?? 0)
    const dispatch = useDispatch()
    useEffect(() => {
        if (!hasRaceState(Race.raceStates, GenericRealTimeTrackerRecall(Tracker))) {
            return
        }
        const clickedButtons = ButtonGroup.GenericRealTimeTrackerRaceStates.filter((b) =>
            hasRaceState(Race.raceStates, b.RaceState)
        )
        const calculatedOrder = clickedButtons?.length > 0 ? Math.max(...clickedButtons.map((b) => b.Order)) + 1 : 1
        setOrder(calculatedOrder)
    }, [Race, ButtonGroup, Tracker])
    const buttons = ButtonGroup.GenericRealTimeTrackerRaceStates.filter((b) => b.Order === order).sort(
        (a, b) => a.HorizontalOrder - b.HorizontalOrder
    )

    if (buttons.length === 0) {
        return null
    }
    return (
        <Grid container direction={'row'} spacing={1}>
            {buttons.map((b) => {
                return (
                    <Grid item key={b.RaceState}>
                        <Tooltip title={b.Explanation}>
                            <>
                                {b.RequireConfirmation && (
                                    <ButtonWithConfirmation
                                        buttonText={b.ButtonText}
                                        buttonColor={b.IsSecondaryButton ? 'secondary' : 'primary'}
                                        onConfirmed={async () => {
                                            const emitRaceStates = async (
                                                buttonToSubmit: GenericRealTimeTrackerRaceState
                                            ) => {
                                                await createRaceState(
                                                    {
                                                        apiURLOverride: Tracker.ApiURLOverride,
                                                        raceID: Race.id,
                                                        type: buttonToSubmit.RaceState,
                                                        latencyInSeconds: latency,
                                                        practiceMode: PracticeMode,
                                                        videoTimestamp: null,
                                                        videoSource: VideoSource,
                                                        genericHistoricalTrackerID: null,
                                                        genericRealTimeTrackerID: Tracker.ID,
                                                    },
                                                    dispatch
                                                )
                                                UpdateUpcomingRaces()
                                            }
                                            emitRaceStates(b)
                                            setOrder(order + 1)
                                        }}
                                    />
                                )}
                                {!b.RequireConfirmation && (
                                    <Button
                                        color={b.IsSecondaryButton ? 'secondary' : 'primary'}
                                        variant="contained"
                                        size={'small'}
                                        onClick={() => {
                                            const emitRaceStates = async (
                                                buttonToSubmit: GenericRealTimeTrackerRaceState
                                            ) => {
                                                await createRaceState(
                                                    {
                                                        apiURLOverride: Tracker.ApiURLOverride,
                                                        raceID: Race.id,
                                                        type: buttonToSubmit.RaceState,
                                                        latencyInSeconds: latency,
                                                        practiceMode: PracticeMode,
                                                        videoTimestamp: null,
                                                        videoSource: VideoSource,
                                                        genericHistoricalTrackerID: null,
                                                        genericRealTimeTrackerID: Tracker.ID,
                                                    },
                                                    dispatch
                                                )
                                                UpdateUpcomingRaces()
                                            }
                                            emitRaceStates(b)
                                            setOrder(order + 1)
                                        }}
                                    >
                                        {b.ButtonText}
                                    </Button>
                                )}
                            </>
                        </Tooltip>
                    </Grid>
                )
            })}
        </Grid>
    )
}

const OptionsRaceStateButtonGroup = ({
    ButtonGroup,
    Race,
    VideoSource,
    UpdateUpcomingRaces,
    PracticeMode,
    Tracker,
}: RaceStateButtonGroupProps) => {
    const [fullRace, setFullRace] = useState(Race)
    const [shouldFetchRace, setShouldFetchRace] = useState(false)
    const latency = useSelector((state: RootStateType) => state?.latencyStore?.latencyInSeconds ?? 0)
    const dispatch = useDispatch()
    useEffect(() => {
        const fetchCurrentRace = async () => {
            try {
                const fullRace = await getRace(
                    Race.raceDate,
                    Race.trackCode,
                    Race.raceNumber,
                    false,
                    true,
                    Tracker.ID,
                    null
                )
                setFullRace(fullRace)
                setShouldFetchRace(false)
            } catch (err) {
                getErrorData(err, dispatch)
            }
        }
        if (shouldFetchRace) {
            fetchCurrentRace()
        }
    }, [Race.raceDate, Race.trackCode, Race.raceNumber, Tracker, shouldFetchRace, dispatch])
    return (
        <>
            <Grid container direction={'row'} spacing={1}>
                {ButtonGroup.GenericRealTimeTrackerRaceStates.sort((a, b) => a.Order - b.Order).map((button) => {
                    const hasClickedButton = hasRaceState(fullRace.raceStates, button.RaceState)
                    return (
                        <Grid item key={button.RaceState}>
                            <Tooltip title={button.Explanation}>
                                <>
                                    {button.RequireConfirmation && (
                                        <ButtonWithConfirmation
                                            buttonText={button.ButtonText}
                                            buttonColor={button.IsSecondaryButton ? 'secondary' : 'primary'}
                                            onConfirmed={async () => {
                                                const emitRaceStates = async (
                                                    buttonToSubmit: GenericRealTimeTrackerRaceState
                                                ) => {
                                                    await createRaceState(
                                                        {
                                                            apiURLOverride: Tracker.ApiURLOverride,
                                                            raceID: Race.id,
                                                            type: buttonToSubmit.RaceState,
                                                            latencyInSeconds: latency,
                                                            practiceMode: PracticeMode,
                                                            videoTimestamp: null,
                                                            videoSource: VideoSource,
                                                            genericHistoricalTrackerID: null,
                                                            genericRealTimeTrackerID: Tracker.ID,
                                                        },
                                                        dispatch
                                                    )
                                                    UpdateUpcomingRaces()
                                                    setShouldFetchRace(true)
                                                }
                                                emitRaceStates(button)
                                            }}
                                        />
                                    )}
                                    {!button.RequireConfirmation && (
                                        <Button
                                            color={button.IsSecondaryButton ? 'secondary' : 'primary'}
                                            variant="contained"
                                            size={'small'}
                                            disabled={hasClickedButton}
                                            onClick={() => {
                                                const emitRaceStates = async (
                                                    buttonToSubmit: GenericRealTimeTrackerRaceState
                                                ) => {
                                                    await createRaceState(
                                                        {
                                                            apiURLOverride: Tracker.ApiURLOverride,
                                                            raceID: Race.id,
                                                            type: buttonToSubmit.RaceState,
                                                            latencyInSeconds: latency,
                                                            practiceMode: PracticeMode,
                                                            videoTimestamp: null,
                                                            videoSource: VideoSource,
                                                            genericHistoricalTrackerID: null,
                                                            genericRealTimeTrackerID: Tracker.ID,
                                                        },
                                                        dispatch
                                                    )
                                                    UpdateUpcomingRaces()
                                                    setShouldFetchRace(true)
                                                }
                                                emitRaceStates(button)
                                            }}
                                        >
                                            {button.ButtonText}
                                        </Button>
                                    )}
                                </>
                            </Tooltip>
                        </Grid>
                    )
                })}
            </Grid>
        </>
    )
}

export const hasRaceState = (raceStates: RaceState[], raceState: string) => {
    return raceStates.some((rs) => rs.type === raceState)
}

export const hasRaceStateForHistoricalModule = (
    raceStates: RaceState[],
    raceState: string,
    historicalTrackerID: number
) => {
    return raceStates.some((rs) => rs.type === raceState && rs.genericHistoricalTrackerID === historicalTrackerID)
}
