import { Race } from '../../models/race'
import {
    GenericRealTimeTracker,
    GenericRealTimeTrackerRaceState,
    GenericRealTimeTrackerRecall,
} from '../../services/generic_real_time_tracker'
import {
    Button,
    Card,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    Grid,
    IconButton,
    InputLabel,
    Menu,
    MenuItem,
    Select,
    Typography,
} from '@material-ui/core'
import React, { useState } from 'react'
import { DragHandle } from '../common/SortableRunnerTrackerComponents'
import { makeStyles } from '@material-ui/core/styles'
import { useDispatch, useSelector } from 'react-redux'
import { RaceButtons } from './RaceButtons'
import { RunnerButtons } from './RunnerButtons'
import { createRaceState } from '../../services/create_race_state'
import { RootStateType } from '../../store'
import { getErrorData } from '../../services/get_error'
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state'
import { MoreVert } from '@material-ui/icons'
import createPersistedState from 'use-persisted-state'
import { MenuSettingWithDefault } from '../common/MenuSettingWithDefault'
import { RealTimeTrackerVideoController } from './RealTimeTrackerVideoController'

interface Props {
    tracker: GenericRealTimeTracker
    race: Race
    updateUpcomingRaces: () => void
}

const useStyles = makeStyles((theme) => ({
    cardRoot: {
        padding: theme.spacing(1),
    },
    header: {
        width: '100%',
        marginRight: 'auto',
    },
    '@keyframes blinker': {
        from: { opacity: 1 },
        to: { opacity: 0 },
    },
    alertChip: {
        backgroundColor: 'red',
        animationName: '$blinker',
        animationDuration: '1s',
        animationTimingFunction: 'linear',
        animationIterationCount: 'infinite',
    },
    raceButtons: {
        marginBottom: theme.spacing(2),
    },
}))

const useRunnerButtonsWidthState = createPersistedState<number>('rt-runner-buttons-width')

export const defaultShowJockeySilks = (country: string, raceType: string) => {
    const value = localStorage.getItem(`JockeySilks.${country}.${raceType}`)
    return value === null || value === 'true'
}
export const setDefaultShowJockeySilks = (country: string, raceType: string, value: boolean) => {
    const valueString = value ? 'true' : 'false'
    localStorage.setItem(`JockeySilks.${country}.${raceType}`, valueString)
}

export const defaultShowRunnerNames = (country: string, raceType: string) => {
    const value = localStorage.getItem(`RunnerNames.${country}.${raceType}`)
    return value !== null && value === 'true'
}
export const setDefaultShowRunnerNames = (country: string, raceType: string, value: boolean) => {
    const valueString = value ? 'true' : 'false'
    localStorage.setItem(`RunnerNames.${country}.${raceType}`, valueString)
}

export const defaultSortByDrawNumber = (country: string, raceType: string) => {
    const value = localStorage.getItem(`SortByDrawNumber.${country}.${raceType}`)
    return value !== null && value === 'true'
}
export const setDefaultSortByDrawNumber = (country: string, raceType: string, value: boolean) => {
    const valueString = value ? 'true' : 'false'
    localStorage.setItem(`SortByDrawNumber.${country}.${raceType}`, valueString)
}

export const defaultButtonsBeside = (module: string, country: string, raceType: string) => {
    const value = localStorage.getItem(`ButtonsBeside.${module}.${country}.${raceType}`)
    return value === 'true'
}

export const setDefaultButtonsBeside = (module: string, country: string, raceType: string, value: boolean) => {
    const valueString = value ? 'true' : 'false'
    localStorage.setItem(`ButtonsBeside.${module}.${country}.${raceType}`, valueString)
}

export const RaceCard = ({ tracker, race, updateUpcomingRaces }: Props) => {
    const classes = useStyles()
    const startTrackingRaceState = `${tracker.TrackingType.toUpperCase()}_START_TRACKING`
    const [startedTracking, updateStartedTracking] = useState(race.hasRaceState(startTrackingRaceState))
    const [showJockeySilks, setShowJockeySilks] = useState(defaultShowJockeySilks(race.country, race.raceType))
    const [showRunnerNames, setShowRunnerNames] = useState(defaultShowRunnerNames(race.country, race.raceType))
    const [sortByDrawNumber, setSortByDrawNumber] = useState(defaultSortByDrawNumber(race.country, race.raceType))
    const [buttonsBeside, setButtonsBeside] = useState(defaultButtonsBeside(tracker.Name, race.country, race.raceType))
    const [runnerNumberFilter, setRunnerNumberFilter] = useState<string[]>([])
    const [runnerButtonFilter, setRunnerButtonFilter] = useState<string[]>([])
    const [videoSource, setVideoSource] = useState(tracker.VideoSources[0]?.Source ?? 'xpressbet')
    const [backupVideoSources, setBackupVideoSources] = useState<string[]>([])
    const latency = useSelector((state: RootStateType) => state.latencyStore?.latencyInSeconds ?? 0)
    const [runnerButtonsWidth, setRunnerButtonsWidth] = useRunnerButtonsWidthState(8)
    const dispatch = useDispatch()
    return (
        <>
            <Card className={classes.cardRoot}>
                <Grid container direction={'column'} spacing={1} justifyContent={'center'} alignItems={'center'}>
                    <RaceCardHeader
                        race={race}
                        tracker={tracker}
                        updateUpcomingRaces={updateUpcomingRaces}
                        startedTracking={startedTracking}
                        showJockeySilks={showJockeySilks}
                        setShowJockeySilks={setShowJockeySilks}
                        sortByDrawNumber={sortByDrawNumber}
                        setSortByDrawNumber={setSortByDrawNumber}
                        buttonsBeside={buttonsBeside}
                        setButtonsBeside={setButtonsBeside}
                        setVideoSource={setVideoSource}
                        videoSource={videoSource}
                        runnerButtonsWidth={runnerButtonsWidth}
                        setRunnerButtonsWidth={setRunnerButtonsWidth}
                        runnerNumberFilter={runnerNumberFilter}
                        setRunnerNumberFilter={setRunnerNumberFilter}
                        runnerButtonFilter={runnerButtonFilter}
                        setRunnerButtonFilter={setRunnerButtonFilter}
                        showRunnerNames={showRunnerNames}
                        setShowRunnerNames={setShowRunnerNames}
                        setBackupVideoSources={setBackupVideoSources}
                        backupVideoSources={backupVideoSources}
                    />
                    <Grid item>
                        <Grid container direction={'row'} spacing={1} justifyContent={'center'}>
                            {startedTracking && buttonsBeside && (
                                <Grid item>
                                    <Grid container direction={'column'} spacing={1}>
                                        <Grid item className={classes.raceButtons}>
                                            <RaceButtons
                                                tracker={tracker}
                                                race={race}
                                                videoSource={videoSource}
                                                updateUpcomingRaces={updateUpcomingRaces}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <RunnerButtons
                                                Race={race}
                                                VideoSource={videoSource}
                                                Tracker={tracker}
                                                RunnerNumberFilter={runnerNumberFilter}
                                                ShowJockeySilks={showJockeySilks}
                                                UpdateUpcoming={updateUpcomingRaces}
                                                RunnerButtonsWidth={runnerButtonsWidth}
                                                SortByDrawNumber={sortByDrawNumber}
                                                ShowRunnerNames={showRunnerNames}
                                                RunnerButtonFilter={runnerButtonFilter}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            )}
                            <Grid item>
                                <RealTimeTrackerVideoController
                                    race={race}
                                    tracker={tracker}
                                    videoSource={videoSource}
                                />
                            </Grid>
                            {backupVideoSources.length > 0 &&
                                backupVideoSources.map((source) => (
                                    <Grid item>
                                        <RealTimeTrackerVideoController
                                            race={race}
                                            tracker={tracker}
                                            videoSource={source}
                                        />
                                    </Grid>
                                ))}
                        </Grid>
                    </Grid>
                    {!startedTracking && (
                        <Grid item>
                            <Button
                                color={'primary'}
                                variant="contained"
                                size={'small'}
                                onClick={async () => {
                                    const emitRaceState = async () => {
                                        await createRaceState(
                                            {
                                                apiURLOverride: tracker.ApiURLOverride,
                                                raceID: race.id,
                                                type: startTrackingRaceState,
                                                latencyInSeconds: latency,
                                                practiceMode: tracker.PracticeMode,
                                                videoTimestamp: null,
                                                videoSource: videoSource,
                                                genericHistoricalTrackerID: null,
                                                genericRealTimeTrackerID: tracker.ID,
                                            },
                                            dispatch
                                        )
                                        updateUpcomingRaces()
                                    }
                                    emitRaceState()
                                    updateStartedTracking(true)
                                }}
                            >
                                Start Tracking
                            </Button>
                        </Grid>
                    )}
                    {startedTracking && !buttonsBeside && (
                        <>
                            <Grid item className={classes.raceButtons}>
                                <RaceButtons
                                    tracker={tracker}
                                    videoSource={videoSource}
                                    race={race}
                                    updateUpcomingRaces={updateUpcomingRaces}
                                />
                            </Grid>
                            <Grid item>
                                <RunnerButtons
                                    Race={race}
                                    Tracker={tracker}
                                    VideoSource={videoSource}
                                    ShowJockeySilks={showJockeySilks}
                                    UpdateUpcoming={updateUpcomingRaces}
                                    RunnerButtonsWidth={runnerButtonsWidth}
                                    SortByDrawNumber={sortByDrawNumber}
                                    RunnerNumberFilter={runnerNumberFilter}
                                    ShowRunnerNames={showRunnerNames}
                                    RunnerButtonFilter={runnerButtonFilter}
                                />
                            </Grid>
                        </>
                    )}
                </Grid>
            </Card>
        </>
    )
}

export const RaceCardHeader = ({
    race,
    tracker,
    updateUpcomingRaces,
    startedTracking,
    showJockeySilks,
    setShowJockeySilks,
    sortByDrawNumber,
    setSortByDrawNumber,
    buttonsBeside,
    setButtonsBeside,
    videoSource,
    setVideoSource,
    runnerButtonsWidth,
    setRunnerButtonsWidth,
    runnerNumberFilter,
    setRunnerNumberFilter,
    showRunnerNames,
    setShowRunnerNames,
    runnerButtonFilter,
    setRunnerButtonFilter,
    setBackupVideoSources,
    backupVideoSources,
}: {
    race: Race
    tracker: GenericRealTimeTracker
    updateUpcomingRaces: () => void
    startedTracking: boolean
    showJockeySilks: boolean
    setShowJockeySilks: (show: boolean) => void
    sortByDrawNumber: boolean
    setSortByDrawNumber: (sortByDrawNumber: boolean) => void
    buttonsBeside: boolean
    setButtonsBeside: (buttonsBeside: boolean) => void
    setVideoSource: (videoSource: string) => void
    videoSource: string
    runnerButtonsWidth: number
    setRunnerButtonsWidth: (numRunnerButtonsWidth: number) => void
    runnerNumberFilter: string[]
    setRunnerNumberFilter: (runnerNumberFilter: string[]) => void
    showRunnerNames: boolean
    setShowRunnerNames: (showRunnerNames: boolean) => void
    runnerButtonFilter: string[]
    setRunnerButtonFilter: (runnerButtonFilter: string[]) => void
    setBackupVideoSources: (videoSources: string[]) => void
    backupVideoSources: string[]
}) => {
    const dispatch = useDispatch()
    const latency = useSelector((state: RootStateType) => state.latencyStore?.latencyInSeconds ?? 0)
    const classes = useStyles()
    const extraHeaderButtons = tracker.GenericRealTimeTrackerRaceStateGroups.filter(
        (rsg) =>
            (rsg.RaceType === null || rsg.RaceType === race.raceType) &&
            (rsg.SubRaceType === null || rsg.SubRaceType === race.raceSubType) &&
            (rsg.StartType === null || rsg.StartType === race.startType) &&
            rsg.ButtonColumn === 0
    ).sort((a, b) => (a.ButtonColumnOrder ?? 0) - (b.ButtonColumnOrder ?? 0))
    const handleRunnerNumberFilterChange = (event: any) => {
        setRunnerNumberFilter(event.target.value)
    }
    const handleRunnerButtonFilterChange = (event: any) => {
        setRunnerButtonFilter(event.target.value)
    }
    return (
        <>
            <Grid item className={classes.header}>
                <Grid container direction={'row'} spacing={1} alignItems={'center'}>
                    <Grid item>
                        <DragHandle />
                    </Grid>
                    <Grid item>
                        <Typography variant={'h5'}>
                            {race.longTrackName} {race.raceNumber}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Chip
                            label={`MTP: ${race.mtp}`}
                            variant={'default'}
                            className={race.mtp <= 2 && !startedTracking ? classes.alertChip : ''}
                        />
                    </Grid>
                    <Grid item>
                        <Chip label={`${race.numberOfHorses} horses`} variant={'default'} />
                    </Grid>
                    <Grid item>
                        <Chip label={race.raceType} variant="default" />
                    </Grid>
                    {race.raceSubType && (
                        <Grid item>
                            <Chip label={race.raceSubType} variant="default" />
                        </Grid>
                    )}
                    {race.startType && (
                        <Grid item>
                            <Chip label={race.startType} variant="default" />
                        </Grid>
                    )}
                    <Grid item>
                        <PopupState variant="popover" popupId="demo-popup-menu">
                            {(popupState) => (
                                <React.Fragment>
                                    <IconButton {...bindTrigger(popupState)}>
                                        <MoreVert />
                                    </IconButton>
                                    <Menu {...bindMenu(popupState)}>
                                        {tracker && (
                                            <>
                                                <MenuItem>
                                                    <InputLabel>Runner Buttons</InputLabel>
                                                    <Select
                                                        style={{ marginLeft: '10px' }}
                                                        multiple
                                                        value={runnerButtonFilter}
                                                        onChange={handleRunnerButtonFilterChange}
                                                    >
                                                        {tracker.GenericRealTimeTrackerRunnerEvents.map((button) => (
                                                            <MenuItem value={button.ShortTextButton}>
                                                                {button.ShortTextButton}
                                                            </MenuItem>
                                                        ))}
                                                        {tracker.GenericRealTimeTrackerRunnerEventGroups.map(
                                                            (group) => (
                                                                <MenuItem value={group.Name}>{group.Name}</MenuItem>
                                                            )
                                                        )}
                                                    </Select>
                                                </MenuItem>
                                                <MenuItem
                                                    onClick={() => {
                                                        setRunnerButtonFilter([])
                                                        popupState.close()
                                                    }}
                                                >
                                                    Clear Runner Button Filter
                                                </MenuItem>
                                            </>
                                        )}
                                        <MenuItem>
                                            <InputLabel>Runner Numbers</InputLabel>
                                            <Select
                                                style={{ marginLeft: '10px' }}
                                                multiple
                                                value={runnerNumberFilter}
                                                onChange={handleRunnerNumberFilterChange}
                                            >
                                                {race.runners.map((runner) => (
                                                    <MenuItem value={runner.programNumber}>
                                                        {runner.programNumber}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </MenuItem>
                                        <MenuItem
                                            onClick={() => {
                                                setRunnerNumberFilter([])
                                                popupState.close()
                                            }}
                                        >
                                            Clear Runner Number Filter
                                        </MenuItem>
                                        <MenuSettingWithDefault
                                            toEnableText="Show Jockey Silks"
                                            toDisableText="Hide Jockey Silks"
                                            isEnabled={showJockeySilks}
                                            setState={setShowJockeySilks}
                                            defaultQualifier={`${race?.raceType} Races`}
                                            setDefaultState={
                                                race
                                                    ? (value) =>
                                                          setDefaultShowJockeySilks(
                                                              race?.country,
                                                              race?.raceType,
                                                              value
                                                          )
                                                    : undefined
                                            }
                                            closePopup={popupState.close}
                                        />
                                        <MenuSettingWithDefault
                                            toEnableText="Sort By Draw Number"
                                            toDisableText="Sort By Runner Number"
                                            isEnabled={sortByDrawNumber}
                                            setState={setSortByDrawNumber}
                                            defaultQualifier={`${race?.raceType} Races`}
                                            setDefaultState={
                                                race
                                                    ? (value) =>
                                                          setDefaultSortByDrawNumber(
                                                              race?.country,
                                                              race?.raceType,
                                                              value
                                                          )
                                                    : undefined
                                            }
                                            closePopup={popupState.close}
                                        />
                                        <MenuSettingWithDefault
                                            toEnableText="Show Runner Names"
                                            toDisableText="Hide Runner Names"
                                            isEnabled={showRunnerNames}
                                            setState={setShowRunnerNames}
                                            defaultQualifier={`${race?.raceType} Races`}
                                            setDefaultState={
                                                race
                                                    ? (value) =>
                                                          setDefaultShowRunnerNames(
                                                              race?.country,
                                                              race?.raceType,
                                                              value
                                                          )
                                                    : undefined
                                            }
                                            closePopup={popupState.close}
                                        />
                                        <MenuSettingWithDefault
                                            toEnableText="Move buttons to BESIDE (left of) video"
                                            toDisableText="Move buttons to BELOW video"
                                            isEnabled={buttonsBeside}
                                            setState={setButtonsBeside}
                                            defaultQualifier={`${race?.raceType} Races in ${tracker.Name}`}
                                            setDefaultState={
                                                race
                                                    ? (value) =>
                                                          setDefaultButtonsBeside(
                                                              tracker.Name,
                                                              race?.country,
                                                              race?.raceType,
                                                              value
                                                          )
                                                    : undefined
                                            }
                                            closePopup={popupState.close}
                                        />
                                        {race && (
                                            <MenuItem
                                                onClick={() => {
                                                    setRunnerButtonsWidth(runnerButtonsWidth + 1)
                                                    popupState.close()
                                                }}
                                            >
                                                Increase runner buttons width
                                            </MenuItem>
                                        )}
                                        {race && (
                                            <MenuItem
                                                onClick={() => {
                                                    setRunnerButtonsWidth(runnerButtonsWidth - 1)
                                                    popupState.close()
                                                }}
                                            >
                                                Decrease runner buttons width
                                            </MenuItem>
                                        )}

                                        {tracker.VideoSources.length > 1 &&
                                            tracker.VideoSources.map((videoSourceOption) => (
                                                <MenuItem
                                                    onClick={() => {
                                                        setVideoSource(videoSourceOption.Source)
                                                        popupState.close()
                                                    }}
                                                >
                                                    Use {videoSourceOption.Source} video
                                                </MenuItem>
                                            ))}

                                        {tracker.VideoSources.length > 1 &&
                                            tracker.VideoSources.filter(
                                                (videoSourceOption) => videoSourceOption.Source !== videoSource
                                            ).map((videoSourceOption) => (
                                                <MenuItem
                                                    onClick={() => {
                                                        if (!backupVideoSources.includes(videoSourceOption.Source)) {
                                                            setBackupVideoSources([
                                                                ...backupVideoSources,
                                                                videoSourceOption.Source,
                                                            ])
                                                        } else {
                                                            setBackupVideoSources(
                                                                backupVideoSources.filter(
                                                                    (source) => source !== videoSourceOption.Source
                                                                )
                                                            )
                                                        }
                                                        popupState.close()
                                                    }}
                                                >
                                                    {!backupVideoSources.includes(videoSourceOption.Source) && (
                                                        <>Add backup video: {videoSourceOption.Source}</>
                                                    )}
                                                    {backupVideoSources.includes(videoSourceOption.Source) && (
                                                        <>Remove backup video: {videoSourceOption.Source}</>
                                                    )}
                                                </MenuItem>
                                            ))}
                                    </Menu>
                                </React.Fragment>
                            )}
                        </PopupState>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <Grid container direction={'row'} spacing={1} alignItems={'center'}>
                    {extraHeaderButtons &&
                        extraHeaderButtons.length > 0 &&
                        extraHeaderButtons.map((b) => {
                            if (b.GenericRealTimeTrackerRaceStates.length !== 1) {
                                getErrorData(
                                    new Error(
                                        `more than one button in header button group ${b.Name} (not supported) - reach out to support`
                                    ),
                                    dispatch
                                )
                                return null
                            }
                            const button = b.GenericRealTimeTrackerRaceStates[0]
                            return (
                                <Grid item key={b.Name}>
                                    {button.RequireConfirmation && (
                                        <ButtonWithConfirmation
                                            buttonText={button.ButtonText}
                                            buttonColor={button.IsSecondaryButton ? 'secondary' : 'primary'}
                                            onConfirmed={async () => {
                                                const emitRaceStates = async (
                                                    buttonToSubmit: GenericRealTimeTrackerRaceState
                                                ) => {
                                                    await createRaceState(
                                                        {
                                                            apiURLOverride: tracker.ApiURLOverride,
                                                            raceID: race.id,
                                                            type: buttonToSubmit.RaceState,
                                                            latencyInSeconds: latency,
                                                            practiceMode: tracker.PracticeMode,
                                                            videoTimestamp: null,
                                                            videoSource: videoSource,
                                                            genericHistoricalTrackerID: null,
                                                            genericRealTimeTrackerID: tracker.ID,
                                                        },
                                                        dispatch
                                                    )
                                                    updateUpcomingRaces()
                                                }
                                                emitRaceStates(button)
                                            }}
                                        />
                                    )}
                                    {!button.RequireConfirmation && (
                                        <Button
                                            color={button.IsSecondaryButton ? 'secondary' : 'primary'}
                                            variant="contained"
                                            size={'small'}
                                            onClick={() => {
                                                const emitRaceStates = async (
                                                    buttonToSubmit: GenericRealTimeTrackerRaceState
                                                ) => {
                                                    await createRaceState(
                                                        {
                                                            apiURLOverride: tracker.ApiURLOverride,
                                                            raceID: race.id,
                                                            type: buttonToSubmit.RaceState,
                                                            latencyInSeconds: latency,
                                                            practiceMode: tracker.PracticeMode,
                                                            videoTimestamp: null,
                                                            videoSource: videoSource,
                                                            genericHistoricalTrackerID: null,
                                                            genericRealTimeTrackerID: tracker.ID,
                                                        },
                                                        dispatch
                                                    )
                                                    updateUpcomingRaces()
                                                }
                                                emitRaceStates(button)
                                            }}
                                        >
                                            {button.ButtonText}
                                        </Button>
                                    )}
                                </Grid>
                            )
                        })}
                    <Grid item>
                        <ButtonWithConfirmation
                            buttonText={'Video Issues'}
                            buttonColor={'secondary'}
                            onConfirmed={async () => {
                                await createRaceState(
                                    {
                                        apiURLOverride: tracker.ApiURLOverride,
                                        raceID: race.id,
                                        type: `${tracker.TrackingType.toUpperCase()}_VIDEO_ISSUES`,
                                        latencyInSeconds: latency,
                                        practiceMode: tracker.PracticeMode,
                                        videoTimestamp: null,
                                        videoSource: videoSource,
                                        genericHistoricalTrackerID: null,
                                        genericRealTimeTrackerID: tracker.ID,
                                    },
                                    dispatch
                                )
                                updateUpcomingRaces()
                            }}
                        />
                    </Grid>
                    <Grid item>
                        <ButtonWithConfirmation
                            buttonText={'Skip'}
                            buttonColor={'primary'}
                            onConfirmed={async () => {
                                await createRaceState(
                                    {
                                        apiURLOverride: tracker.ApiURLOverride,
                                        raceID: race.id,
                                        type: `${tracker.TrackingType.toUpperCase()}_SKIP_RACE`,
                                        latencyInSeconds: latency,
                                        practiceMode: tracker.PracticeMode,
                                        videoTimestamp: null,
                                        videoSource: videoSource,
                                        genericHistoricalTrackerID: null,
                                        genericRealTimeTrackerID: tracker.ID,
                                    },
                                    dispatch
                                )
                                updateUpcomingRaces()
                            }}
                        />
                    </Grid>
                    <Grid item>
                        <ButtonWithConfirmation
                            buttonText={'Race Recall'}
                            buttonColor={'primary'}
                            onConfirmed={async () => {
                                await createRaceState(
                                    {
                                        apiURLOverride: tracker.ApiURLOverride,
                                        raceID: race.id,
                                        type: GenericRealTimeTrackerRecall(tracker),
                                        latencyInSeconds: latency,
                                        practiceMode: tracker.PracticeMode,
                                        videoTimestamp: null,
                                        videoSource: videoSource,
                                        genericHistoricalTrackerID: null,
                                        genericRealTimeTrackerID: tracker.ID,
                                    },
                                    dispatch
                                )
                                updateUpcomingRaces()
                            }}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

interface ButtonWithConfirmationProps {
    buttonText: string
    buttonColor: 'primary' | 'secondary'
    onConfirmed: () => void
}

export const ButtonWithConfirmation = ({ buttonText, buttonColor, onConfirmed }: ButtonWithConfirmationProps) => {
    const [dialogOpen, setDialogOpen] = useState(false)
    return (
        <>
            <Button variant="contained" color={buttonColor} size={'small'} onClick={() => setDialogOpen(true)}>
                {buttonText}
            </Button>
            <Dialog
                open={dialogOpen}
                onClose={() => setDialogOpen(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">Are you sure? ({buttonText})</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            setDialogOpen(false)
                            onConfirmed()
                        }}
                        color="primary"
                        autoFocus
                    >
                        Yes, I'm sure
                    </Button>
                    <Button onClick={() => setDialogOpen(false)} color="secondary">
                        No
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
